import React from 'react'
// import { Link, graphql } from 'gatsby'
import classNames from 'classnames'
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import style from 'assets/jss/styles/pages/mainPageStyle.js'
import { makeStyles } from '@material-ui/core/styles'
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

const useStyles = makeStyles(style)

const SectionReformas = ({ data }) => {
  const classes = useStyles()
  
  return (
    <div className={classes.container}>
      <div dangerouslySetInnerHTML={{__html: data.reformasJson.content.childMarkdownRemark.html}} />

      <div dangerouslySetInnerHTML={{__html: data.reformasJson.contentPisos.childMarkdownRemark.html}} />
      <GridContainer className={classes.imgContainer}>
      {data.reformasJson.galleryPisos.map((obj, i) => {
        return (
          <GridItem key={i} xs={12} sm={4} md={4} className={classes.mlAuto + " " + classes.middle}>
            <Img
              fluid={obj.image.childImageSharp.fluid}
              alt={obj.alt}
              title={obj.title}
            />
          </GridItem>
        )
      })}
      </GridContainer> 

      <div dangerouslySetInnerHTML={{__html: data.reformasJson.contentLocales.childMarkdownRemark.html}} />
      <GridContainer className={classes.imgContainer}>
      {data.reformasJson.galleryLocales.map((obj, i) => {
        return (
          <GridItem key={i} xs={12} sm={4} md={4} className={classes.mlAuto + " " + classes.middle}>
            <Img
              fluid={obj.image.childImageSharp.fluid}
              alt={obj.alt}
              title={obj.title}
            />
          </GridItem>
        )
      })}
      </GridContainer>

      <div dangerouslySetInnerHTML={{__html: data.reformasJson.contentKitchen.childMarkdownRemark.html}} />
      <GridContainer className={classes.imgContainer}>
      {data.reformasJson.galleryKitchen.map((obj, i) => {
        return (
          <GridItem key={i} xs={12} sm={4} md={4} className={classes.mlAuto + " " + classes.middle}>
            <Img
              fluid={obj.image.childImageSharp.fluid}
              alt={obj.alt}
              title={obj.title}
            />
          </GridItem>
        )
      })}
      </GridContainer>

      <div dangerouslySetInnerHTML={{__html: data.reformasJson.contentBathrooms.childMarkdownRemark.html}} />
      <GridContainer className={classes.imgContainer}>
      {data.reformasJson.galleryBathrooms.map((obj, i) => {
        return (
          <GridItem key={i} xs={6} sm={6} md={3} className={classes.mlAuto + " " + classes.middle + " vertical"}>
            <Img
              fluid={obj.image.childImageSharp.fluid}
              alt={obj.alt}
              title={obj.title}
            />
          </GridItem>
        )
      })}
      </GridContainer>
    </div>
  )
}

export default SectionReformas

