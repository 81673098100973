import React from 'react'
import classNames from 'classnames'
import Layout from 'layout/layout'
import Typist from 'react-typist'
import { graphql } from "gatsby"
import { Link } from 'gatsby'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import Parallax from 'components/Parallax/Parallax.js'
import landingPageStyle from 'assets/jss/styles/pages/landingPageStyle.js'
import { makeStyles } from '@material-ui/core/styles'
import ReformasServices from 'pages-sections/ReformasServices'
import SectionContact from 'pages-sections/SectionContact'
import SectionWorks from 'pages-sections/SectionWorks'
import SectionServices from 'pages-sections/SectionServices'
import SectionReformas from 'pages-sections/SectionReformas'

const useStyles = makeStyles(landingPageStyle);

const texts = [
  "Reformas de baños y cocinas",
  "Reformas integrales de viviendas",
  "Contrucción de viviendas",
  "Arquitectura e ingeniería",
  "Instalaciones eléctricas",
  "Fontanería",
]

const IndexPage = ({ data }) => {
  const [renderMsg, setRenderMsg] = React.useState(true);
  const classes = useStyles()
  const delay = 1500;

  const reactive = () => {
    setTimeout(() => {
      setRenderMsg(true);
    }, 1000)
  }

  const onHeaderTyped = () => {
    reactive();
    setRenderMsg(false);
  }

  return (
    <Layout
      pageTitle="Reformas de pisos y locales en Barcelona y alrededores"
      pageDescription="reformas en Barcelona y alrededores. Reformas integrales de locales comerciales, pisos, cocinas y baños. Equipo con gran experiencia de albañiles, electricistas y fontaneros. La mejor empresa de reformas en Barcelona. Pídenos presupuesto gratuito y sin compromiso."
      headerImageGatsby={data.reformasJson.headerImage.childImageSharp.fluid}
      filter="dark"
      content={
        <React.Fragment>
          {renderMsg ? (
            <h2 className={classes.typist}>
              <Typist
                className="typist-header"
                avgTypingDelay={20}
                startDelay={1000}
                onTypingDone={onHeaderTyped}
              >
                <span>{texts[0]}</span>
                <Typist.Backspace count={texts[0].length} delay={delay} />
                <span>{texts[1]}</span>
                <Typist.Backspace count={texts[1].length} delay={delay} />
                <span>{texts[2]}</span>
                <Typist.Backspace count={texts[2].length} delay={delay} />
                <span>{texts[3]}</span>
                <Typist.Backspace count={texts[3].length} delay={delay} />
                <span>{texts[4]}</span>
                <Typist.Backspace count={texts[4].length} delay={delay} />
                <span>{texts[5]}</span>
                <Typist.Backspace count={texts[5].length} delay={delay} />
              </Typist>
            </h2>
          ) : (
            <h2 className={classes.typist}>|</h2>
          )}

          <h4 className={classes.subtitle}>A un precio sin compentencia</h4>

          <Link to="/presupuesto">
            <Button color={'primary'} className={classes.navButton}>
              Solicita Presupuesto
            </Button>
          </Link>

          <br />
        </React.Fragment>
      }
    >
      <SectionServices />
      <ReformasServices classes={classes} />

      <SectionReformas data={data} />
      <SectionContact />
    </Layout>
  )  
}

export default IndexPage

export const query = graphql`
  query ReformaspageQuery {
    site {
      id
      siteMetadata {
        siteTitle
        siteDescription
      }
    }
    reformasJson {
      title
      subtitle
      headerImage {
        childImageSharp {
          fluid(maxHeight: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      content {
        childMarkdownRemark {
          html
        }
      }
      contentBathrooms {
        childMarkdownRemark {
          html
        }
      }
      contentKitchen {
        childMarkdownRemark {
          html
        }
      }
      contentLocales {
        childMarkdownRemark {
          html
        }
      }
      contentPisos {
        childMarkdownRemark {
          html
        }
      }
      galleryBathrooms {
        title
        alt
        image {
          childImageSharp {
            fluid(maxHeight: 400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      galleryKitchen {
        title
        alt
        image {
          childImageSharp {
            fluid(maxHeight: 400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      galleryLocales {
        title
        alt
        image {
          childImageSharp {
            fluid(maxHeight: 400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      galleryPisos {
        title
        alt
        image {
          childImageSharp {
            fluid(maxHeight: 400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;