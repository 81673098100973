import React from 'react'
import { Link } from 'gatsby'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import Button from 'components/CustomButtons/Button.js'
import Subject from '@material-ui/icons/Subject'

import Instalaciones from "assets/img/sections/bombilla1.jpg";
import Construccion from "assets/img/sections/construccion.jpg";
import Fontaneria from "assets/img/sections/fontaneria.jpg";
import Incendio from "assets/img/sections/incendio.jpg";
import Reformas from "assets/img/sections/reformas.jpg";
import Cocina from "assets/img/sections/cocina.jpg";
import Bathroom from "assets/img/sections/bathroom.jpg";
import { LANDING, BANOS, COCINAS, LOCALES, PISOS, ELECTRICISTAS, FONTANEROS, PRESUPUESTO, CONTENEDORES } from 'constants/routes';

const CardLayout = ({ classes, href, title, desc, img = Instalaciones }) => {
  return (
    <GridItem xs={12} md={6} lg={6}>
      <Card background style={{ backgroundImage: `url(${img})` }}>
        <Link to={href}>
          <CardBody background>
            <h3 className={classes.cardTitleWhite}>{title}</h3>
            <p className={classes.cardDescriptionWhite}>{desc}</p>
            <Button round color="danger">
              <Subject /> Ver más
            </Button>
          </CardBody>
        </Link>
      </Card>
    </GridItem>
  )
}

const ReformasServices = ({ classes }) => {
  return (
    <div className={classes.wrapper}>
      <GridContainer>

        <CardLayout 
          classes={classes}
          img={Reformas}
          href={PISOS}
          title="Reformas de Pisos"
          desc='Expertos en reformas de pisos en Barcelona "llave en mano". Nos encargamos de todo el proceso, desde la demolición y tabiquería, hasta las instalaciones, carpintería, pintura y permisos y legalizaciones. Presupuestos personalizados, gratuitos y sin compromiso.'
        />

        <CardLayout 
          classes={classes}
          img={Construccion}
          href={LOCALES}
          title="Reformas de Locales"
          desc="Uno de nuestros puntos fuertes. En Inmovalia Group nos hemos especializado en reformas de locales de pública concurrencia como bares, restaurantes y hoteles. ¡No te la juegues! Entra para más información."
        />

        <CardLayout 
          classes={classes}
          img={Cocina}
          href={COCINAS}
          title="Reformas de Cocinas"
          desc="Inmovalia Group transformará tu vieja cocina en una nueva, luminosa y acogedora cocina. Contamos con profesionales esperimentados para cada etapa de la obra, electricistas, fontaneros y, por supuesto, albañilería"
        />

        <CardLayout 
          classes={classes}
          img={Bathroom}
          href={BANOS}
          title="Reformas de Baños"
          desc="Una de las partes más delicadas de la reforma de un piso. Saber sacarle el máximo partido es todo un arte. Cuéntanos tu idea y te aconsejaremos lo mejor que podamos para hacer realidad tu visión al mejor precio."
        />

        <CardLayout 
          classes={classes}
          img={Instalaciones}
          href={ELECTRICISTAS}
          title="Instalaciones eléctricas"
          desc="Inmovalia Group le ofrece su experiencia en el proyecto e implementación de instalaciones eléctricas. Dimensionar adecuadamente una instalación es esencial para locales comerciales, evitándole futuros problemas."
        />

        <CardLayout 
          classes={classes}
          img={Fontaneria}
          href={FONTANEROS}
          title="Fontaneria"
          desc={"Inmovalia Group le ofrece su amplia experiencia en servicios de fontanería, tanto en instalaciones domésticas como en instalaciones comerciales."}
        />        
      </GridContainer>
    </div>
  )
}

export default ReformasServices
